// src/config/authConfig.jsx

// Supported platforms for authentication
export const SUPPORTED_PLATFORMS = ['twitter', 'telegram', 'github'];

// API Base URL with a default fallback
export const API_BASE_URL =
  import.meta.env.VITE_API_BASE_URL || 'https://fireboiler.com/';

// Authentication endpoints - aligned with Firebase rewrite rules
export const AUTH_ENDPOINTS = {
  // Social authentication
  socialAuthentication: '/socialAuthentication', // Matches firebase.json rewrite rule
  telegramCallback: '/auth/telegram/callback',

  // OAuth endpoints
  initiateOAuth: '/auth/oauth/initiate',
  oauthCallback: '/auth/oauth/callback',
  twitterCallback: '/auth/twitter/callback',
  githubCallback: '/auth/github/callback',

  // Social media management
  linkSocialMedia: '/linkSocialMedia',
  unlinkSocialMedia: '/unlinkSocialMedia',
  getSocialMediaStats: '/getSocialMediaStats',

  // User management
  getUserProfile: '/getUserProfile',
  updateUserProfile: '/updateUserProfile',
  saveScore: '/saveScore',
  leaderboard: '/leaderboard',
  getReferralInfo: '/getReferralInfo',

  // Session management
  refreshToken: '/refreshToken',
  logout: '/logout',

  // Additional endpoints
  telegramWebhook: '/api/telegram-webhook',
  protectedRoute: '/protectedRoute'
};

// Platform-specific configurations with full callback URLs
export const PLATFORM_CONFIG = {
  telegram: {
    botUsername:
      import.meta.env.VITE_TELEGRAM_BOT_USERNAME || 'FireBoiler_bot',
    widgetUrl: 'https://telegram.org/js/telegram-widget.js?22',
    callbackUrl: `${API_BASE_URL}/auth/telegram/callback`
  },
  twitter: {
    callbackUrl: `${API_BASE_URL}/auth/twitter/callback`
  },
  github: {
    callbackUrl: `${API_BASE_URL}/auth/github/callback`
  },
  facebook: {
    callbackUrl: `${API_BASE_URL}/auth/facebook/callback`
  }
};

// Authentication configuration settings
export const AUTH_CONFIG = {
  tokenStorageKey: 'authToken',
  refreshTokenStorageKey: 'refreshToken',
  sessionTimeout: 86400000, // 24 hours
  refreshThreshold: 300000, // 5 minutes before session expiration
  maxRetries: 3,
  retryDelay: 1000, // Retry delay in ms
  maxLogAttempts: 3
};

// Request configuration for API calls
export const REQUEST_CONFIG = {
  timeout: 15000, // 15 seconds timeout
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
};

// Error message configurations
export const ERROR_MESSAGES = {
  invalidToken: 'Invalid or expired authentication token',
  networkError: 'Network error occurred. Please check your connection',
  unauthorized: 'Unauthorized access. Please log in again',
  serverError: 'Server error occurred. Please try again later',
  invalidPlatform: (platform) => `Unsupported platform: ${platform}`,
  authFailed: (platform) => `Authentication failed for ${platform}`,
  timeout: 'Request timed out. Please try again',
  invalidResponse: 'Invalid response received from server',
  noData: 'No data received from server'
};

// Firebase Cloud Functions configuration
export const FUNCTION_CONFIG = {
  region: 'us-central1',
  timeoutSeconds: 60,
  memory: '256MB',
  runtime: 'nodejs20'
};

// CORS configuration for cross-origin requests
export const CORS_CONFIG = {
  origin: [
    'https://fireboiler.com',
    'https://www.fireboiler.com',
    'https://telegram.org',
    'https://*.telegram.org'
  ],
  methods: ['GET', 'POST', 'PUT', 'DELETE', 'OPTIONS'],
  allowedHeaders: ['Content-Type', 'Authorization', 'X-Requested-With'],
  credentials: true
};

// Environment-specific configurations
export const ENV_CONFIG = {
  isDevelopment: import.meta.env.DEV,
  isProduction: import.meta.env.PROD,
  firebaseConfig: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID
  }
};

// Utility functions
export const getEndpointUrl = (endpoint) => `${API_BASE_URL}${endpoint}`;

// Function to validate if a platform is supported
export const isValidPlatform = (platform) => SUPPORTED_PLATFORMS.includes(platform);

// Retrieve platform-specific configurations
export const getPlatformConfig = (platform) => {
  if (!isValidPlatform(platform)) {
    throw new Error(ERROR_MESSAGES.invalidPlatform(platform));
  }
  return PLATFORM_CONFIG[platform];
};

// Main configuration export
export default {
  SUPPORTED_PLATFORMS,
  API_BASE_URL,
  AUTH_ENDPOINTS,
  PLATFORM_CONFIG,
  AUTH_CONFIG,
  REQUEST_CONFIG,
  ERROR_MESSAGES,
  FUNCTION_CONFIG,
  CORS_CONFIG,
  ENV_CONFIG,
  getEndpointUrl,
  isValidPlatform,
  getPlatformConfig
};
